<template>
    <h1 class="title is-size-4">Source Lists</h1>
    <div class="field is-grouped is-grouped-multiline">
        <div class="control">
            <div class="select">
                <select v-model="filters.publisher">
                    <option :value="null">All publishers</option>
                    <option v-for="publisher in publishers"
                        :key="publisher.id"
                        :value="publisher.id">{{ publisher.name }}</option>
                </select>
            </div>
        </div>
        <div class="control">
            <div class="select">
                <select v-model="filters.year">
                    <option :value="null">All years</option>
                    <option v-for="year in meta.lists.years"
                        :key="year.year"
                        :value="year.year">{{ year.year }} ({{ year.count }})</option>
                </select>
            </div>
        </div>
        <div class="control">
            <div class="select">
                <select v-model="filters.type">
                    <option :value="null">All list types</option>
                    <option v-for="type in listTypes"
                        :key="type[0]"
                        :value="type[0]">{{ type[1] }}</option>
                </select>
            </div>
        </div>
        <div class="control">
            <a @click="clearFilters"
                v-if="isFiltered"
                class="button">
                <span class="icon">
                    <span class="mdi mdi-close"></span>
                </span>
                <span>Clear filters</span>
            </a>
        </div>
    </div>
    <pagination-component :total="resultsCount"
        :limit="pagination.limit"
        :offset="pagination.offset"
        @pagechanged="onPageChange">
    </pagination-component>
    <div v-if="loading"
        class="loading">
        <span class="mdi mdi-loading mdi-spin mdi-48px"></span>
    </div>
    <list-results-component :items="items"
        :show-type="true"
        :show-rank="false">
    </list-results-component>
    <pagination-component :total="resultsCount"
        :limit="pagination.limit"
        :offset="pagination.offset"
        @pagechanged="onPageChange">
    </pagination-component>
</template>

<script>
import { LIST_TYPE_LABELS } from '../constants';
import List from '../models/List';
import BaseListComponent from './BaseListComponent';
import ListResultsComponent from './ListResultsComponent';
import PaginationComponent from './PaginationComponent';


export default {
    mixins: [BaseListComponent],
    components: {
        ListResultsComponent,
        PaginationComponent,
    },
    data() {
        return {
            filters: {
                type: null,
                publisher: null,
                year: null,
            },
            publishers: [],
            resultsCount: 0,
        }
    },
    async created() {
        let data = await fetch(`${process.env.VUE_APP_API_URL}publications/?limit=999`)
            .then(resp => resp.json());
        this.publishers = data.results;
    },
    computed: {
        listTypes() {
            return Object.entries(LIST_TYPE_LABELS);
        }
    },
    methods: {
        async loadItems() {
            let data = await fetch(`${process.env.VUE_APP_API_URL}lists/?${new URLSearchParams(this.cleanedFilters)}`)
                .then(resp => resp.json());
            this.items = data.results.map(x => new List(x));
            this.resultsCount = data.count;
        }
    },
}
</script>

<style>
.list-item {
    padding: 1em 0.5em;
    border-bottom: 1px solid silver;
}

.list-item:last-child {
    border: none;
}
</style>